<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="6"
        cols="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                key="DollarSignIcon"
                md="6"
                sm="6"
                class="mb-2 mb-md-0"
              >
                <b-col
                  key="ActivityIcon"
                  md="12"
                  sm="12"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="info"
                      >
                        <feather-icon
                          size="24"
                          icon="ActivityIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        Total Kas Bis
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ totalKasBis | formatRupiah }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- Table Kas -->
    <b-card
      title="Total Kas Bis"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <!-- <b-button
              variant="primary"
              @click="addKeuanganSidebar = true"
            >
              + Tambah Data Kas Bis
            </b-button> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.index + 1 }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(totalKasBis)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: tipe -->
        <template #cell(tipe)="data">
          <b-badge
            :variant="data.value === 'pengeluaran' ? 'light-danger' : 'light-warning' "
            class="text-capitalize"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Image -->
        <template #cell(foto_nota)="data">
          <b-img
            :src="require(`@/assets/images/pages/${data.value}`)"
            height="125"
            alt="Responsive image"
          />
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`Kas-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editKeuangan(data.item, true)"
              >
                Edit
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`Kas-row-${data.item.id}-edit-icon`"
              />

              <b-button
                :id="`Kas-row-${data.item.id}-hapus-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
              <b-tooltip
                title="Hapus"
                :target="`Kas-row-${data.item.id}-hapus-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalKeuangan"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- Table Riwayat -->
    <b-card
      title="Riwayat Kas Bis"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addKeuanganSidebar = true"
            >
              + Tambah Data Kas Bis
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rowsRiwayat"
        responsive
        :fields="columnsRiwayat"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPageRiwayat"
        :current-page="currentPageRiwayat"
        :filter="searchQueryRiwayat"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(jumlah)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: tipe -->
        <template #cell(tipe)="data">
          <b-badge
            :variant="data.value === 'pengeluaran' ? 'light-danger' : 'light-warning' "
            class="text-capitalize"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Image -->
        <template #cell(foto_nota)="data">
          <b-img
            :src="require(`@/assets/images/pages/${data.value}`)"
            height="125"
            alt="Responsive image"
          />
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`Kas-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editKeuangan(data.item, true)"
              >
                Edit
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`Kas-row-${data.item.id}-edit-icon`"
              />

              <b-button
                :id="`Kas-row-${data.item.id}-hapus-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
              <b-tooltip
                title="Hapus"
                :target="`Kas-row-${data.item.id}-hapus-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageRiwayat"
              :total-rows="totalKeuanganRiwayat"
              :per-page="perPageRiwayat"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <AddKeuanganContent
      v-model="addKeuanganSidebar"
      :kas="dataEditKeuangan"
      @kas-sidebar-change="editKeuangan"
      @submit-data-kas="submitData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BMediaAside, BAvatar, BMediaBody,
  BPagination, BTooltip, BButtonGroup, BImg, BBadge,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddKeuanganContent from './Add.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BButtonGroup,
    BImg,
    BBadge,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    AddKeuanganContent,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'No.',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Nama Bis',
          key: 'bis.nama',
        },
        {
          label: 'Jumlah',
          key: 'totalKasBis',
        },
      ],
      columnsRiwayat: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Supir',
          key: 'supir.nama',
        },
        {
          label: 'Nama Bis',
          key: 'bis.nama',
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
        },
        {
          label: 'Jumlah',
          key: 'jumlah',
        },
        {
          label: 'Tipe',
          key: 'tipe',
        },
      ],
      perPage: 5,
      perPageRiwayat: 5,
      totalKeuangan: 0,
      totalKeuanganRiwayat: 0,
      currentPage: 1,
      currentPageRiwayat: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      searchQueryRiwayat: '',
      sortBy: 'id',
      isSortDirDesc: true,
      rowsData: [],
      rowsDataRiwayat: [],
      searchTerm: '',
      addKeuanganSidebar: false,
      dataEditKeuangan: {
        id: '1',
        keterangan: null,
        jenis: null,
        jumlah: null,
        foto_nota: null,
      },
      totalKasBis: 0,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      return this.rowsData
    },
    rowsRiwayat() {
      return this.rowsDataRiwayat
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // eslint-disable-next-line no-return-assign
      this.$http.get('/kas-bis').then(response => {
        this.totalKasBis = response.data.data.totalKasBis

        this.rowsData = response.data.data.kasBisPerbis
        this.rowsDataRiwayat = response.data.data.riwayatKasBis

        this.totalKeuangan = response.data.data.kasBisPerbis.length
        this.totalKeuanganRiwayat = response.data.data.riwayatKasBis.length
      })
    },
    editKeuangan(data, value) {
      this.dataEditKeuangan = data
      this.addKeuanganSidebar = value
    },
    submitData(data) {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

      const formData = new FormData()
      formData.append('id_bis', data.bis)
      formData.append('id_supir', data.supir)
      formData.append('keterangan', data.keterangan)
      formData.append('jumlah', data.jumlah)
      formData.append('tipe', 'pengeluaran')
      formData.append('tanggal', date)

      this.$http.post('/kas-bis', formData).then(response => {
        this.getData()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: response.data.message,
            variant: 'success',
          },
        })
      })
      this.addKeuanganSidebar = false
    },
    removeData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.rowsData = this.rowsData.filter(item => item.id !== id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
