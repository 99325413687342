var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-kas-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addKeuanganSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.kasLocal.id > 0 ? 'Edit Keuangan' : 'Add Keuangan')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Supir","label-for":"supir-kas"}},[_c('validation-provider',{attrs:{"name":"Supir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"supir-kas","options":_vm.optionsSupir,"state":errors.length > 0 ? false:null,"placeholder":"Status Supir"},model:{value:(_vm.kasLocal.supir),callback:function ($$v) {_vm.$set(_vm.kasLocal, "supir", $$v)},expression:"kasLocal.supir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Bis","label-for":"bis-kas"}},[_c('validation-provider',{attrs:{"name":"bis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"bis-kas","options":_vm.optionsBis,"state":errors.length > 0 ? false:null,"placeholder":"Status Supir"},model:{value:(_vm.kasLocal.bis),callback:function ($$v) {_vm.$set(_vm.kasLocal, "bis", $$v)},expression:"kasLocal.bis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"katerangan-kas"}},[_c('validation-provider',{attrs:{"name":"katerangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"katerangan-kas","state":errors.length > 0 ? false:null,"placeholder":"Keterangan"},model:{value:(_vm.kasLocal.keterangan),callback:function ($$v) {_vm.$set(_vm.kasLocal, "keterangan", $$v)},expression:"kasLocal.keterangan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jumlah","label-for":"jumlah-kas"}},[_c('validation-provider',{attrs:{"name":"Jumlah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jumlah-kas","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Jumlah"},model:{value:(_vm.kasLocal.jumlah),callback:function ($$v) {_vm.$set(_vm.kasLocal, "jumlah", $$v)},expression:"kasLocal.jumlah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }